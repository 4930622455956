<template>
    <v-row
            no-gutters
            class="d-flex justify-space-between">
        <v-col
                @click="changeLanguage('en-US')"
                class="ml-16"
                cols="2">
            <v-avatar
                    style="z-index:1"
                    size="65">
                <v-img src="@/assets/en.png"></v-img>
            </v-avatar>
            <v-btn
                    elevation="0"
                    large
                    class="ml-n9 pl-10 py-7">
                English
            </v-btn>
        </v-col>
        <v-col
                @click="changeLanguage('es-US')"
                cols="2">
            <v-avatar
                    style="z-index:1"
                    size="65">
                <v-img src="@/assets/es.png"></v-img>
            </v-avatar>
            <v-btn
                    elevation="0"
                    large
                    class="ml-n9 pl-10 py-7">
                Spanish
            </v-btn>
        </v-col>
        <v-col
                @click="changeLanguage('nv-US')"
                cols="2">
            <v-avatar
                    style="z-index:1"
                    size="65">
                <v-img src="@/assets/nv.png"></v-img>
            </v-avatar>
            <v-btn
                    elevation="0"
                    large
                    class="ml-n9 pl-10 py-7">
                Navajo
            </v-btn>
        </v-col>
        <v-col
                @click="changeLanguage('vi-US')"
                class="mr-16"
                cols="2">
            <v-avatar
                    class="ml-n9"
                    style="z-index:1"
                    size="65">
                <v-img src="@/assets/vi.png"></v-img>
            </v-avatar>
            <v-btn
                    elevation="0"
                    large
                    class="ml-n9 pl-10 py-7">
                Vietnamese
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
    export default {
      name: 'languageBar',
      methods:{
        changeLanguage(language){
          switch(language){
            case 'en-US':
              this.$store.commit('setCurrentLanguage', 'en-US');
              this.$store.dispatch('avatarSpeak', 'I am now speaking in english');
              break;
            case 'es-US':
              //this.$store.commit('clearAvatarSpeech');
              this.$store.commit('setCurrentLanguage', 'es-US');
              this.$store.dispatch('avatarSpeak', 'Estoy aprendiendo español. Por favor regresa más tarde.');
              break;
            case 'nv-US':
              this.$store.commit('setTextBubbleText', 'Dinék’ehjí yáti’ bíhoosh’aah, t’ah hanááshidííłkiń.')
              //this.$store.commit('clearAvatarSpeech');
              break;
            case 'vi-US':
              this.$store.commit('setTextBubbleText', 'Chúng tôi đang học tiếng Việt.  Xin quý vị vui lòng trở lại sau này.')
              break;
            default:
              break;
          }
        }
      }
    }
</script>
