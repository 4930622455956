import * as fb from "@/firebase/index.js";
import axios from 'axios';
import { ApolloClient } from 'apollo-client';
import { createHttpLink, HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import {
  floorQuery,
  allFormQuery,
  mapQuery,
  faqQuery,
  faqCategoryQuery,
  srlGuideQuery,
  formCategoryQuery,
  formTypeQuery,
  formPacketQuery,
  homepageQuery,
  allFormSecondQuery
} from '@/graphql/query';

function today() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  return (mm + '-' + dd + '-' + yyyy);
}

export default {
  state: {
    dataLoaded: false,
    apolloClient: undefined,
    homepage: [],
    floors: [],
    maps: new Map(),
    forms: new Map(),
    faqs: [],
    faqCategory: [],
    srlGuide: new Map(),
    formCategories: [],
    formTypes: [],
    formPackets: new Map(),
    fileCache: null

  },
  getters: {
    getDataLoaded: state => state.dataLoaded,
    getHomepage: state => state.homepage,
    getFloors: state => state.floors,
    getMaps: state => state.maps,
    getFaqs: state => state.faqs,
    getFaqCategories: state => state.faqCategory,
    getSrlGuide: state => state.srlGuide,
    getForms: state => state.forms,
    getFormCategory: state => state.formCategories,
    getFormType: state => state.formTypes,
    getFormPackets: state => state.formPackets
  },
  mutations: {
    setDataLoaded(state, status) {
      state.dataLoaded = status;
    },
    setApolloClient(state, uri) {
      const fragmentMatcher = new IntrospectionFragmentMatcher({
        introspectionQueryResultData: {
          __schema: {
            types: [],
          },
        },
      });
      state.apolloClient = new ApolloClient({
        link: new HttpLink({ uri: uri }),
        cache: new InMemoryCache({ fragmentMatcher })
      })
    },
    setHomepage(state, page) {
      state.homepage.push(page)
    },
    setFloors(state, floor) {
      state.floors.push(floor)
    },
    setMaps(state, map) {
      state.maps.set(map.mapName.toLowerCase(), map)
    },
    setForms(state, form) {
      state.forms.set(form.formName.toLowerCase(), form)
    },
    setFaqs(state, faqs) {
      faqs.forEach(faq => {
        let data = {
          question: faq.question,
          answer: faq.answer.replace(/\n/g, '<br />'),
          category: faq.category
        }
        state.faqs.push(data)
      })
    },
    setFaqCategory(state, faqCategories) {
      state.faqCategory = faqCategories
    },
    setSrlGuide(state, guide) {
      state.srlGuide.set(guide.guideName.toLowerCase(), {
        guideName: guide.guideName,
        category: guide.category,
        srlFile: guide.srlFile.url,
        guideUrl: guide.srlGuideLink
      })
    },
    setFormCategories(state, categories) {
      state.formCategories = categories
    },
    setFormTypes(state, types) {
      state.formTypes = types
    },
    setFormPackets(state, packet) {
      state.formPackets.set(packet.packetName.toLowerCase(), packet)
    }
  },
  actions: {
    createApolloConnection({ commit }, uri) {
      return new Promise((resolve, reject) => {
        if (uri === '' || uri === null) reject('Empty or Invalid URI')
        try {
          commit('setApolloClient', uri)
          resolve('Apollo linked successfully');
        }
        catch (err) {
          reject(err.message);
        }
      })
    },
    fetchHomePage({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        state.apolloClient.query({
          query: homepageQuery
        }).then(items => {
          items.data.homepages.forEach(page => {
            dispatch('saveInCache', page.menuIcon.url)
              .then(response => {
                page.menuIcon.url = response
                commit('setHomepage', page);
              })
          })
          resolve('Homepage fetched successfully.')

        }, error => {
          reject(error.message)
        })

      })
    },
    // Fetch Floor from strapi
    fetchFloors({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        state.apolloClient.query({
          query: floorQuery
        }).then(floors => {
          floors.data.floors.forEach(floor => {
            dispatch('saveInCache', floor.floorImage.url)
              .then(response => {
                floor.floorImage.url = response;
                commit('setFloors', floor)
              })
          })
          resolve('Floor data fetched successfully.')
        }, error => {
          reject(error.message)
        })
      })
    },

    // Fetching Maps from Strapi
    fetchMaps({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        state.apolloClient.query({
          query: mapQuery
        }).then(maps => {
          maps.data.maps.forEach(map => {
            dispatch('saveInCache', map.mapImage.url)
              .then(response => {
                commit('setMaps', {
                  mapName: map.mapName,
                  mapFloor: map.mapFloor,
                  mapImage: response,
                  speech: map.speech
                })
              })
          })
          resolve('Map data fetched successfully.')
        }, error => {
          reject(error.message)
        })
      })
    },

    // Fetch Forms from strapi
    fetchForms({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        state.apolloClient.query({
          query: allFormQuery
        }).then(forms => {
          forms.data.allForms.forEach(form => {
            dispatch('saveInCache', form.formPdf.url)
              .then(response => {
                commit('setForms', {
                  formName: form.formName,
                  formCategory: form.formCategory,
                  displayIcon: form.displayIcon,
                  formPdf: response,
                  pdfUrl: form.pdfUrl
                })
              })
          })
          resolve('Form data fetched successfully.')
        }, error => {
          reject(error.message)
        })


        state.apolloClient.query({
          query: allFormSecondQuery
        }).then(forms => {
          forms.data.allForms.forEach(form => {
            dispatch('saveInCache', form.formPdf.url)
              .then(response => {
                commit('setForms', {
                  formName: form.formName,
                  formCategory: form.formCategory,
                  displayIcon: form.displayIcon,
                  formPdf: response,
                  pdfUrl: form.pdfUrl
                })
              })
          })
          resolve('Form data fetched successfully.')
        }, error => {
          reject(error.message)
        })
      })
    },

    fetchFaqs({ commit, state }) {
      return new Promise((resolve, reject) => {
        state.apolloClient.query({
          query: faqQuery
        }).then(faqs => {
          commit('setFaqs', faqs.data.faqs);
          resolve('FAQs fetched successfully.')
        }, error => {
          reject(error.message)
        })
      })
    },

    fetchFaqCategory({ commit, state }) {
      return new Promise((response, reject) => {
        state.apolloClient.query({
          query: faqCategoryQuery
        }).then(faqCategory => {
          commit('setFaqCategory', faqCategory.data.faqCategories)
          response('FAQ categories fetched successfully')
        }, error => {
          reject(error.message)
        })
      })
    },

    fetchSrlGuide({ commit, dispatch, state }) {
      return new Promise((response, reject) => {
        state.apolloClient.query({
          query: srlGuideQuery
        }).then(srlGuides => {
          srlGuides.data.srlGuides.forEach(guide => {
            dispatch('saveInCache', guide.srlFile.url)
              .then(response => {
                guide.srlFile.url = response;
                commit('setSrlGuide', guide);
              })
          })
          response('SRL guides fetched successfully.')
        }, error => {
          reject(error.message);
        })
      })
    },

    fetchFormCategories({ commit, state }) {
      return new Promise((response, reject) => {
        state.apolloClient.query({
          query: formCategoryQuery
        }).then(categories => {
          commit('setFormCategories', categories.data.formCategories)
          response('Form categories fetched successfully.')
        }, error => {
          reject(error.message)
        })
      })
    },

    fetchFormType({ commit, state }) {
      return new Promise((response, reject) => {
        state.apolloClient.query({
          query: formTypeQuery
        }).then(types => {
          commit('setFormTypes', types.data.formTypes)
          response('Form types fetched successfully.')
        }, error => {
          reject(error.message)
        })
      })
    },

    fetchFormPackets({ commit, dispatch, state }) {
      return new Promise((response, reject) => {
        state.apolloClient.query({
          query: formPacketQuery
        }).then(packets => {
          packets.data.formPackets.forEach(packet => {
            let files = [];
            packet.packetFiles.forEach(file => {
              dispatch('saveInCache', file.url)
                .then(response => {
                  files.push(response)
                })
            })
            commit('setFormPackets', {
              packetName: packet.packetName,
              packetDesc: packet.packetDesc,
              packetLink: packet.packetLink,
              packetFiles: files
            })
          })
          response('Form packets fetched successfully.')
        }, error => {
          reject(error.message)
        })
      })
    },
    saveInCache({ state, getters }, path) {
      return new Promise((response, reject) => {
        state.fileCache = caches.open('fileCache')
          .then(cache => {
            cache.match(getters.getCMSlink + path)
              .then(cacheResponse => {
                if (cacheResponse) {
                  console.log(`${path} FOUND in Cache`)
                  return (cacheResponse.blob())
                    .then(blob => {
                      response(URL.createObjectURL(blob))
                    })
                }
                else {
                  console.log(`${path} ADDED in Cache`)
                  cache.add(getters.getCMSlink + path)
                  cache.match(getters.getCMSlink + path)
                    .then(cacheResponse => {
                      return (cacheResponse.blob())
                        .then(blob => {
                          response(URL.createObjectURL(blob))
                        })
                    })
                }
              })
          })
      })
    },
    searchOdysseybyName({ state }, keyword) {
      return new Promise((response, reject) => {
        fb.odysseyCollection
          .where("courtLocation", "==", "santafe")
          .where("dateCreated", "==", today())
          .where("partyOneName", "==", keyword.toLowerCase())
          .get()
          .then(querySnapshot => {
            if (querySnapshot.empty) reject('Sorry I did not find any results for this user name.')
            response(querySnapshot);
          })
      })
    },
    searchOdysseybyCaseNo({ state }, caseNumber) {
      return new Promise((response, reject) => {
        fb.odysseyCollection
          .where("courtLocation", "==", "santafe")
          .where("dateCreated", "==", today())
          .where("caseNo", "==", caseNumber)
          .get()
          .then(querySnapshot => {
            if (querySnapshot.empty) reject('Sorry I did not find any results for this case number.')
            response(querySnapshot);
          })

      })
    },
    searchByCaseNo({ state }, caseNumber) {
      return new Promise((response, reject) => {
        let config = {
          method: 'get',
          url: 'https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/caseSearch/a763cd056f1b2405788443b7197e0708',
          params: {
            caseNumber: caseNumber,
            courtName: 'first judicial district santa fe county'
          }
        };

        axios(config)
          .then(result => {
            //   console.log('DATA X CHANGE: ', result.data)
            response(result.data);
          })
          .catch(function (error) {
            // console.log(error);
            reject(error)
          });

      })
    },
    searchByName({ state }, nameObject) {
      return new Promise((response, reject) => {
        let parameter;
        if (nameObject.middleName === '' || nameObject.middleName === null) {
          parameter = {
            firstName: (nameObject.firstName.toLowerCase()).trim(),
            lastName: (nameObject.lastName.toLowerCase()).trim(),
            courtName: 'first judicial district santa fe county'
          }
        }
        else {
          parameter = {
            firstName: (nameObject.firstName.toLowerCase()).trim(),
            lastName: (nameObject.lastName.toLowerCase()).trim(),
            middleName: (nameObject.middleName.toLowerCase()).trim(),
            courtName: 'first judicial district santa fe county'
          }
        }
        let config = {
          method: 'get',
          url: 'https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/caseSearch/a763cd056f1b2405788443b7197e0708',
          params: parameter
        };

        axios(config)
          .then(result => {
            console.log('DATA X CHANGE: ', result.data)
            response(result.data);
          })
          .catch(function (error) {
            // console.log(error);
            reject(error)
          });

      })
    }
  }
}
