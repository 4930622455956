<template>
    <v-row
            justify="center"
            align="start"            
            no-gutters>
        <v-col
                class="py-8"
                cols="12"
                align="center">
            <span                
                class="text-h2 font-weight-bold grey--text text--darken-3" >
                How Can I Help You?
            </span>
        </v-col>
        <v-col cols="9" class="py-2">
            <v-row
                    no-gutters
                    class="fill-height">
                <v-col
                        align="center"
                        cols="6"
                        v-for="(page,index) in getHomepage"
                        :key="index">
                    <v-card
                            @click="goto(page)"
                            class="menuItem d-flex flex-column align-center justify-center my-6">
                        <v-img
                            :src="page.menuIcon.url"
                            width="60%"
                            contain>
                        </v-img>
                        <v-card-text class="text-h4 primary--text font-weight-bold">
                            {{page.menuName}}
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <v-divider class="pb-5 mx-10"></v-divider>
            <languageBar></languageBar>
        </v-col>
    </v-row>
</template>

<script>
    import languageBar from '@/components/languageBar'
    import {mapGetters} from 'vuex'

    export default {
      name: 'menuMatrix',
      components:{
        languageBar
      },
      data(){
        return{
        }
      },
      computed:{
          ...mapGetters(['getHomepage', 'getCMSlink', 'getTouchCount', 'isUserLooking'])
      },
      methods:{
          goto(page){
              if(page.pageType.length !== 0){
                  switch(page.pageType[0].__typename){
                    case 'ComponentHomepageNavigation':
                        this.$router.push('/'+page.pageType[0].pathName.toLowerCase());
                        this.$store.commit('setPageHeader', page.pageType[0].pageHeader)
                        this.$store.commit('setQnaKeyword', '')

                      // To start session using touch - when no face detected
                        if(!this.isUserLooking){
                          this.$store.commit('setTouchCount', this.getTouchCount + 1)
                          if(this.getTouchCount === 1){
                            // start time
                            this.$store.commit('setStartUserSession', new Date())

                            //session start object
                            this.$store.commit('setTouchRequest', {
                              module: 'Default',
                              action: 'Touch Detected',
                              response: 'User Session Started using Touch',
                              timeStamp: new Date(),
                              requestType: 'Session'
                            })
                          }
                        }

                        // Will be called irrespective of Touch Count
                        this.$store.commit('setTouchRequest', {
                          module: 'Home Page',
                          action: 'navigate',
                          response: page.pageType[0].pathName,
                          timeStamp: new Date(),
                          requestType: 'Touch'
                        })
                    break;
                    case 'ComponentHomepageDialogBox':
                    break;
                    default:
                    break;
                    }
              }
              this.$store.commit('setDefaultBubbleText', true)
              this.$store.dispatch('avatarSpeak', page.speech)
          },
      }
    }
</script>

<style>
    .menuItem{
        border: thick solid #6b1530 !important;
        width: 310px;
        height: 280px
    }
</style>
