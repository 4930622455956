<template>
    <v-row
            no-gutters
            id="avatar-wrapper"
            :style="{ height: height + 'px', width : width + 'px'}">
        <v-col
                align="center"
                justify="center"
                id="divVHSS">
        </v-col>
        <div id="avatar-accessories">
                  <div
                    style="width: 480px; margin-left: 280px"
                    v-if="width > 600">
                    <v-card
                      style="border-radius: 30px !important"
                      class="pa-5 d-flex justify-center"
                      color="rgb(255, 255, 255, 0.7)">
                      <img
                      width="400"
                      src="@/assets/logo.png">
                    </v-card>

                  </div>
        </div>
    </v-row>

</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
      name: 'avatar',
      props: {
        initialWidth: {
          type: Number,
          required: true
        },
      },
      data(){
        return{
          width: this.initialWidth,
        }
      },
      computed:{
        ...mapGetters(['getCurrentLanguage', 'getAvatarSpeech', 'getIsSpeaking','getDefaultBubbleText']),
        height: {
          get() {
            if(this.$route.path === '/' || this.$route.path === '/loadData'){
              // return this.width * 1.125 - 180
              return this.width - 65
            }
            return this.width - 15

          },
          set(newVal) {
            this.width = newVal
          }
        },
        spVoice: function () {
          switch (this.getCurrentLanguage) {
            case 'es-US':
              return 4;
            default: // en
              return 3;
          }
        },
        spLang: function () {
          switch (this.getCurrentLanguage) {
            case 'es-US':
              return 2;
            default: // en
              return 1;
          }
        },
        spEngine: function () {
          switch (this.getCurrentLanguage) {
            case 'es-US':
              return 4;
            default: // en
              return 3;
          }
        }
      },
      watch:{
        width: function () {
          if(this.$route.path === '/'){
              window.dynamicResize(this.width, this.height);
            }
            else{
              window.dynamicResize(this.width, this.height);
            }
        },
        getAvatarSpeech(newVal){
          this.speak(newVal);
        }
      },
      methods:{
        speak: function(speakText) {
          if(this.getIsSpeaking)
            window.stopSpeech();
          if (this.getCurrentLanguage === 'en') {
            window.sayText('<prosody rate="+20%">' + speakText + '</prosody>', this.spVoice, this.spLang, this.spEngine);
          }
          else {
            window.sayText(speakText, this.spVoice, this.spLang, this.spEngine);
          }
        },
        forceResize: function () {
          window.dynamicResize(this.width, this.height);
        },
      },
      mounted(){
        window.vh_sceneLoaded = () => {
          this.forceResize();
          this.$store.commit('setAvatarLoaded', true);
        },
        window.vh_audioStarted = () => {
          this.$store.commit('setIsSpeaking', true);
        },
        window.vh_talkEnded = () => {
          // clientApp.avatar.isTalking = false;
          // if (clientApp.avatar.promise) {
          //   clientApp.avatar.promise.resolve({interrupted : false});
          //   clientApp.avatar.promise = null;
          // }
          // Has to removed once all languages are added
          this.$store.commit('setCurrentLanguage', 'en-US');
          this.$store.commit('setIsSpeaking', false);
          if(!this.getDefaultBubbleText){
            this.$store.commit('setTextBubbleText', 'Tap on the microphone button, to ask me a question.')
          }
        }
      }
    }

</script>

<style>
    #divVHSS {
        width: 100%;
        z-index: 0 !important;
    }
    #divVHSS-inner{
      left: -10px !important
    }
    .character, .vhss_main_container, ._html5Player{
      /* height: 1085px !important; */
      margin-top: -10px !important;
      z-index: -1 !important;
      position: initial !important;
    }
    #avatar-accessories {
      z-index: 1;
      height: 82%;
      /* width: 100%; */
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      position: absolute;
    }
</style>
