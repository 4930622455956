import gql from 'graphql-tag'

export const faqQuery = gql`
query faqQuery {
  faqs {
    id
    question
    answer
    category
  }
}
`

export const homepageQuery = gql`
query homeQuery {
  homepages {
    id
    speech    
    menuName     
    pageType{
      ... on ComponentHomepageNavigation{
        __typename
        pathName
        pageHeader
      }
      ... on ComponentHomepageDialogBox{
        __typename
        type
        media {
          id
          url
        }
        textList      
      }
    }
    menuIcon {
      id
      url
    }
  }
}
`

export const mapQuery = gql`
query mapQuery {
  maps{
    id
    mapName
    mapFloor
    speech
    mapImage {
      id
      url
    }
  }
}
`

export const floorQuery = gql`
query floorQuery {
  floors (sort: "floorNumber"){ 
    id
    floorName
    floorNumber
    speech
    floorImage {
      id
      url
    }
  }
}
`

export const faqCategoryQuery = gql`
query faqCategoryQuery {
  faqCategories {
    id
    categoryName
    displayType
  }
}
`

export const srlGuideQuery = gql`query srlGuideQuery {
  srlGuides{
    id
    category
    guideName
    srlGuideLink
    srlFile {
      id
      url
    }
  }
}`

export const formCategoryQuery = gql `
query formCategoryQuery {
  formCategories {
    id
    categoryName
    displayType
    categoryType
  }
}
`

export const allFormQuery = gql`
query allFormQuery {
  allForms(start: 0) {
    id
    formName
    displayIcon
    formCategory
    pdfUrl
    formPdf{
      id
      url
    }
  }
}
`

export const allFormSecondQuery = gql`
query allFormQuery {
  allForms(start: 100) {
    id
    formName
    displayIcon
    formCategory
    pdfUrl
    formPdf{
      id
      url
    }
  }
}
`





export const formTypeQuery = gql`
query formTypeQuery {
    formTypes(sort: "id") {
        id
        formSubType
        displayType
    }
}
`

export const formPacketQuery = gql`
query formPacketQuery {
    formPackets {
        id
        packetName
        packetDesc
        packetLink
        packetFiles{
            id
            url
        }
    }
}
`
