<template>
<div>
    <v-row
        class="mt-n7"
          no-gutters
          align="center"
          justify="center">
      <v-col cols="6">
          <v-card flat>
              <v-card-text class="d-flex justify-center">
                  <v-text-field
                          placeholder="Enter a keyword to search for FAQ"
                          class="text-h5"
                          hide-details
                          outlined
                          height="55px"
                          rounded
                          v-model="searchKeyword"
                          @click="expand = !expand"
                          :append-icon="searchBarIcon"
                          @click:append="searchKeyword = ''">
                  </v-text-field>
              </v-card-text>
              <v-expand-transition>
                  <v-card-text
                          class="d-flex justify-center ma-0 pa-0"
                          v-if="expand">
                      <SimpleKeyboard
                              class="keyboard"
                              @onKeyPress="onKeyPress"
                              @onChange="onChange">
                      </SimpleKeyboard>
                  </v-card-text>
              </v-expand-transition>
          </v-card>
      </v-col>

      <v-col cols="12" align="center">
          <v-card
                  v-if="expand || searchKeyword !== ''"
                  @click="expand = false"
                  :ripple="false"
                  class="mt-2 containerBorder"
                  width="95%"
                  flat
                  :height="cardHeight">
              <v-card-text
                      v-if="matchingSrlGuides.length === 0 && matchingFaqs.length === 0">
                  <span class="text-h4">
                      No matching Question/Keyword found.
                  </span>
              </v-card-text>
              <div v-else>
                  <v-expansion-panels
                          class="px-6"
                          focusable
                          dark>
                      <v-expansion-panel
                              v-for="(item,index) in matchingFaqs"
                              class="mt-2"
                              :key="index">
                          <v-expansion-panel-header
                                  color="primary"
                                  class="white--text headline"
                                  @click="clickedFaq(item.question)">
                              {{ item.question }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content
                                  class="black--text px-2 pt-2 text-justify text-h5"
                                  color="white">
                                  <p v-html="item.answer"></p>
<!--                                      <span class="">-->
<!--                                          {{ item.answer }}-->
<!--                                      </span>-->
                          </v-expansion-panel-content>
                      </v-expansion-panel>
                  </v-expansion-panels>

                  <v-row
                          class="pa-5"
                          no-gutters>
                      <v-col v-for="srlGuide in matchingSrlGuides"
                             :key="srlGuide.id"
                             @click="viewDoc(srlGuide.srlFile, srlGuide.guideName, 'Guide')"
                             class="pa-3"
                             cols="4">
                          <v-card
                                  height="90"
                                  flat
                                  class="PDFborder" >
                              <v-row
                                      no-gutters
                                      class="fill-height"
                                      align="center">
                                  <v-col cols="2">
                                      <v-icon
                                              size="45"
                                              color="primary lighten-3">
                                        mdi-file-pdf-box
                                      </v-icon>
                                  </v-col>
                                  <v-col
                                          cols="10"
                                          align="start">
                                      <span class="headline">
                                          {{srlGuide.guideName}}
                                      </span>
                                  </v-col>
                              </v-row>
                          </v-card>
                      </v-col>
                  </v-row>
              </div>
          </v-card>

          <v-card
                  class="mt-1"
                  width="95%"
                  flat
                  v-else>
              <v-tabs
                      height="80"
                      color="primary"
                      v-model="tab">
                  <v-tab
                          active-class="tabClass"
                          v-for="item in getFaqCategories"
                          :key="item.id"
                          class="text-h4 pa-5"
                          @click="expand = false;">
                      {{item.categoryName}}
                  </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                  <v-tab-item
                          v-for="type in getFaqCategories"
                          :key="type.id">
                      <v-card
                              height="560"
                              class="containerBorder"
                              flat>
                          <v-expansion-panels
                                  v-if="type.displayType === 'expansionPanel'"
                                  class="px-6"
                                  focusable
                                  dark>
                              <v-expansion-panel
                                      v-for="(item, index) in filteredFaqs(type.categoryName)"
                                      class="mt-2"
                                      :key="index">
                                  <v-expansion-panel-header
                                          color="primary"
                                          class="white--text headline"
                                          @click="clickedFaq(item.question)">
                                      {{item.question}}
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content
                                          class="black--text px-2 pt-2 text-justify text-h5"
                                          color="white">
                                    <p v-html="item.answer"></p>
                                  </v-expansion-panel-content>
                              </v-expansion-panel>
                          </v-expansion-panels>

                          <v-row
                                  v-if="type.displayType === 'button'"
                                  class="pa-5"
                                  no-gutters>
                              <v-col v-for="(srlGuide, index) in getSrlGuide.values()"
                                     :key="index"
                                     @click="viewDoc(srlGuide.srlFile, srlGuide.guideName, 'Guide')"
                                     class="pa-3"
                                     cols="4">
                                  <v-card
                                          height="90"
                                          flat
                                          class="PDFborder" >
                                      <v-row
                                              no-gutters
                                              class="fill-height"
                                              align="center">
                                          <v-col cols="2">
                                              <v-icon
                                                      size="45"
                                                      color="primary lighten-3">
                                                mdi-file-pdf-box
                                              </v-icon>
                                          </v-col>
                                          <v-col
                                                  cols="10"
                                                  align="start">
                                              <span class="headline">
                                                  {{srlGuide.guideName | trimLength}}
                                              </span>
                                          </v-col>
                                      </v-row>
                                  </v-card>
                              </v-col>
                          </v-row>
                      </v-card>
                  </v-tab-item>
              </v-tabs-items>
          </v-card>
      </v-col>
  </v-row>
</div>
</template>

<script>

import {mapGetters} from 'vuex';
import SimpleKeyboard from "@/components/SimpleKeyboard";


export default {
    name: 'faqs',
    components:{
        SimpleKeyboard,
    },
    data(){
        return{
            tab: 0,
            input: '',
            searchKeyword: '',
            searchBarIcon: 'mdi-magnify',
            expand: false,
            cardHeight: 560,
        }
    },
    computed:{
        ...mapGetters(['getFaqs', 'getFaqCategories', 'getSrlGuide','getQnaKeyword']),
        matchingFaqs() {
            return this.getFaqs.filter(faq => faq.question.toLowerCase().includes(this.searchKeyword.toLowerCase()))
        },
        matchingSrlGuides() {
            let temp = []
            this.getSrlGuide.forEach(guide => {
                if(guide.guideName.toLowerCase().includes(this.searchKeyword.toLowerCase())){
                    temp.push(guide)
                }
            })
            return temp;
        }
    },
    watch:{
        expand(newVal) {
            if (newVal) {
                this.searchBarIcon = 'mdi-close-circle'
                this.cardHeight = 380;
            } else {
                this.searchBarIcon = 'mdi-magnify'
                this.cardHeight = 560
            }
        },
      getQnaKeyword(newVal){
        this.searchKeyword = newVal
      },
    },
    methods:{
        onChange(input) {
            this.searchKeyword = input;
        },
        onKeyPress(button) {
            if (button === "{bksp}")
                this.searchKeyword = this.searchKeyword.slice(0, -1);
        },
        filteredFaqs(category){
            return this.getFaqs.filter(faq => faq.category.toLowerCase().includes(category.toLowerCase()))
        },
        viewDoc(pdfUrl, fileName, type){
            this.$store.dispatch('openPdfViewer', {
                url: pdfUrl,
                type: type,
                name: fileName
            })

          // Touch usage
          this.$store.commit('setTouchRequest', {
            module: 'Frequently Asked Question',
            action: 'Show SRL Guide',
            response: fileName,
            timeStamp: new Date(),
            requestType: 'Touch'
          })
        },

      clickedFaq(question) {
        this.$store.commit('setTouchRequest', {
          module: 'Frequently Asked Question',
          action: 'Show Faq',
          response: question,
          timeStamp: new Date(),
          requestType: 'Touch'
        })
      }
    },
    filters:{
        trimLength(val){
            if(val.length < 90) {return val}
            return `${val.substring(0,70)}...`
        }
  },
  mounted(){
      this.searchKeyword = this.getQnaKeyword
  }
}
</script>

<style>
    .tabClass{
        background-color: #6b1530;
        color: white !important;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    .containerBorder{
        border-width: 3px;
        border-style: solid;
        border-color: #6b1530 !important;
        border-radius: 8px !important;
        overflow-y: scroll;
    }
    .PDFborder{
        border-style: solid;
        border-width: 2px;
        border-color: #6b1530 !important;
        overflow-y: scroll;
        border-radius: 15px !important;
    }


</style>
